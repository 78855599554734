import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import About from "./components/About"
import Home from "./components/Home"
import Projects from "./components/Projects";
import Posts from "./components/Posts"
import "./App.css"

function App() {
  return (
    <Router>
      <div className="navbar">
        <Link to="">Home</Link>
        <Link to="about">About</Link>
        <Link to="posts">Posts</Link>
        <Link to="projects">Projects</Link>
      </div>
      <Routes>
        <Route path="" element={<Home />}  />
        <Route path="posts" element={<Posts />} />
        <Route path="projects" element={<Projects />} />
        <Route path="about" element={<About />} />
      </Routes>
    </Router>
  );
}

export default App;
